import React, { useState, useEffect } from "react";

import { Team } from "./Team";
import { Gallery } from "./gallery";
import { PageTitle } from "./pageTitle";
import { GridElements } from "./gridelements";


import JsonData from "../data/data.json";
import "../App.css";


const Account = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);


    return (
        <>
        <PageTitle title="Conta Digital" sub="Quem é Levve Bank faz acontecer todo dia" sub2="Seja no app ou no navegador, tenha um extrato inteligente, receba e faça transferencias via PIX, gere boletos e pague só os que receber, investimentos seguro e empréstimos para atender as suas necessidades e tenha multiusuários com diferentes permissões para acessar a conta."/>
        <GridElements data={landingPageData.GridElements}/>
        <Team data={landingPageData.Team} />
        <Gallery data={landingPageData.Gallery} /></>
    )
};

export default Account;